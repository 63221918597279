import "./_lib/modernizr";
import "../scss/application.scss";
import jquery from "jquery";

window.$ = window.jQuery = jquery;

import Website from "./_modules/website";
import Home from "./_modules/home";
import Video from "./_modules/video";
import Slideshow from "./_modules/slideshow";
import Lazy from "./_modules/lazy";

$(function () {
  new Website();
  new Home();
  new Video();
  new Slideshow();
  new Lazy();
});
