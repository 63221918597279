import LazyLoad from "vanilla-lazyload";

export default class Lazy {
  constructor() {
    this.initLazy();
  }

  initLazy() {
    const lazyLoadInstance = new LazyLoad({
      elements_selector: ".ll",
      callback_loaded: (el) => {
        $(el).parent().addClass("loaded");
      },
    });
  }
}
