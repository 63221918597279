import Flickity from "flickity";

export default class Slideshow {
  constructor() {
    if ($(".js-slideshow").length > 0) {
      this.carousel = null;
      this.initClickItem();
    }
  }

  initClickItem() {
    $(".js-gridItem").on("click", (e) => {
      const index = $(".js-gridItem").index(e.currentTarget);
      $(".js-slideshowContainer").css({ opacity: 0, display: "block" });
      if (!this.carousel) this.initSlideshow();
      this.positionSlideshow(index);
      this.changeCursor();
      this.changeCaption(index);
      this.closeSlideshow();
      $(".js-slideshowContainer").animate({ opacity: 1 }, 300);
    });
  }

  initSlideshow() {
    var slideOptions = {
      cellSelector: ".js-slide",
      cellAlign: "left",
      setGallerySize: false,
      contain: true,
      prevNextButtons: false,
      pageDots: false,
      wrapAround: true,
      percentPosition: false,
      draggable: true,
      selectedAttraction: 0.1,
      friction: 0.6,
      lazyLoad: true,
    };

    this.initFlickity($(".js-slideshow"), slideOptions);
  }

  closeSlideshow() {
    $(".js-slideshowClose").on("click", () => {
      $(".js-slideshowContainer").animate({ opacity: 0 }, 300, () => {
        $(".js-slideshowContainer").removeAttr("style");
      });
    });
  }

  initFlickity(element, options) {
    this.carousel = new Flickity($(element)[0], options);
    this.carousel.on("staticClick", (e, pointer) => {
      if (this.checkPosition(element, pointer)) {
        this.carousel.next(true);
      } else {
        this.carousel.previous(true);
      }
    });

    this.carousel.on("change", (index) => {
      this.changeCaption(index);
      this.scrollToItem(index);
    });
  }

  positionSlideshow(index) {
    this.carousel.select(index, true, true);
  }

  changeCursor() {
    $(".js-slideshow").on("mousemove", (e) => {
      if (this.checkPosition(e.currentTarget, e)) {
        $(e.currentTarget).removeClass("w-cursor");
      } else {
        $(e.currentTarget).addClass("w-cursor");
      }
    });
  }

  changeCaption(index) {
    const item = $(".js-slide").eq(index);
    const caption = $(item).find(".js-slideCaption").text();
    $(".js-slideshowCaption").text(caption);
  }

  scrollToItem(index) {
    const item = $(".js-gridItem").eq(index);
    const offset = window.matchMedia("(min-width: 800px)").matches ? $(item).parent().offset().top - 24 : $(item).offset().top - 16;
    $("html, body").animate({ scrollTop: offset }, 400);
  }

  positionSlideshow(index) {
    this.carousel.select(index, true, true);
  }

  checkPosition(el, pointer) {
    const windowWidth = $(el).outerWidth();
    const percentage = pointer.pageX / windowWidth;
    const x = Math.round(percentage);
    return x;
  }
}
