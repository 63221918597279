import Player from "@vimeo/player";

export default class Video {
  constructor() {
    this.initVideo();
  }

  initVideo() {
    $(".js-playButton").on("click", (e) => {
      const button = $(e.currentTarget);
      const video = $(button).siblings(".js-gridVideo");
      const poster = $(button).siblings(".js-gridPoster");
      const newPlayer = new Player(video, { url: $(video).data("vimeo") });
      newPlayer.ready().then(function () {
        $(poster).animate({ opacity: 0 }, 300);
        $(button).animate({ opacity: 0 }, 300, () => {
          $(poster).css({ pointerEvents: "none" });
          $(button).remove();
          newPlayer.play();
        });
      });
    });
  }
}
